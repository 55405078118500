export enum QBStatementTab {
  statements = 'statements',
  submitted = 'submitted',
  needRouteCheck = 'needRouteCheck',
}

export enum QBStatementWindow {
  lastWeek = 0,
  last4Weeks = 1,
  last8Weeks = 2,
}

export interface FinQBStatementCountTab {
  statements?: number,
  submitted?: number,
  needRouteCheck?: number,
}