import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzSelectModule } from "ng-zorro-antd/select";
import { RouterModule } from "@angular/router";
import { FinQuickbookStatementList } from "./list";
import { QuickbookStatementFilter } from "./list/filter";
import { FinQuickbookStatementDetail } from "./detail";
import { NzGridModule } from "ng-zorro-antd/grid";
import { CreateByUserModule } from "@app/admin/components/create-by-user/module";
import { ConfirmCreateQBStatementDialog } from "./list/create-statement-dialog";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzInputModule } from "ng-zorro-antd/input";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzIconModule,
    NzButtonModule,
    NzBadgeModule,
    NzSelectModule,
    NzGridModule,
    NzToolTipModule,
    NzTagModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzInputModule,
    CreateByUserModule,
  ],
  declarations: [
    FinQuickbookStatementList,
    QuickbookStatementFilter,
    FinQuickbookStatementDetail,
    ConfirmCreateQBStatementDialog,
  ],
  exports: [
    FinQuickbookStatementList,
    FinQuickbookStatementDetail,
  ]
})
export class QuickbookStatementModule {}