<div>
  <div>The statement allows you to combine all payment requests from a carrier within a specific period into one bill for a single payment.</div>
  <div class="top15">
    <div class="flex flex-space-between">
      <div>Date range (Route completed date)</div>
      <div nz-checkbox [(ngModel)]="needCustomWindow">Custom window</div>
    </div>
    <div *ngIf="!needCustomWindow" class="readonly-box top10">
      {{ windowLabel }} ({{ showRouteCompletedWindow() }})
    </div>
    <div *ngIf="needCustomWindow" class="top10">
      <nz-row [nzGutter]="[8,8]">
        <nz-col [nzXs]="12">
          <nz-input-group nzAddOnAfter="EST">
            <nz-date-picker nzFormat="yyyy-MM-dd" nzPlaceHolder="From Date"
              [(ngModel)]="customWindowFrom"
              (ngModelChange)="onChangeCustomWindow($event, 'from')"
              style="width: 100%"
            >
            </nz-date-picker>
          </nz-input-group>
        </nz-col>
        <nz-col [nzXs]="12">
          <nz-input-group nzAddOnAfter="EST">
            <nz-date-picker nzFormat="yyyy-MM-dd" nzPlaceHolder="To Date" 
              [(ngModel)]="customWindowTo"
              (ngModelChange)="onChangeCustomWindow($event, 'to')"  
              style="width: 100%"
            >
            </nz-date-picker>
          </nz-input-group>
        </nz-col>
      </nz-row>
    </div>
  </div>
</div>