<div>
  <div style="width: 100%;">
    <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" [nzSize]="'small'" style="width: 100%" type="card">
      <ng-container *ngFor="let tab of filterTabs">
        <nz-tab [nzTitle]="titleTemplate" style="font-weight: 600"></nz-tab>
        <ng-template #titleTemplate>
          <nz-badge [nzCount]="tab?.total" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
            <span>{{tab.title}}</span>
          </nz-badge>
        </ng-template>
      </ng-container>
    </nz-tabset>
  </div>

  <div class="header-filter">
    <form [formGroup]="formInput" nz-form>
      <div class="group-filter">
        <div *ngIf="'accountIds' as key" class="filter-item">
          <nz-select [formControlName]="key" nzBackdrop="true" 
            nzAllowClear nzShowSearch nzMode="multiple"
            [nzLoading]="isFetchingFinAccounts"
            nzPlaceHolder="Search by Payee"
            (ngModelChange)="onFilterChange(key, $event)"
            style="width: 100%;">
            <nz-option *ngFor="let item of listFinAccounts" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </div>
        <div *ngIf="'windowOption' as key" class="filter-item">
          <nz-select [formControlName]="key" nzBackdrop="true" 
            nzAllowClear nzShowSearch
            nzPlaceHolder="route completed window"
            (ngModelChange)="onFilterChange(key, $event)"
            style="width: 100%;">
            <nz-option *ngFor="let option of windowOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>
      </div>
    </form>

    <div class="group-btn">
      <button nz-button nzTheme="outline" nzType="primary" [nzLoading]="isExporting" [disabled]="isExporting" (click)="onBtnExport()">
        <i nz-icon nzType="download" nzTheme="outline"></i>
        Export
      </button>
      <button *ngIf="shouldShowCreateStatement" nz-button nzTheme="outline" nzType="primary" [nzLoading]="isCreatingStatement" [disabled]="isCreatingStatement" (click)="openDialogCreateStatement()">
        Create statement
      </button>
    </div>
  </div>
</div>