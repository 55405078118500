<div>
  <div quickbook-statement-filter 
    [countSum]="countSum"
    (refresh)="onRefresh()"
    (onFilterTabChanged)="onFilterTabChanged($event)">
  </div>

  <div class="list-body top20">
    <div class="flex flex-space-between bottom20">
      <div>Show: {{ listData.length }} {{ listData.length > 1 ? 'items' : 'item'}}</div>
      <div>Total Payable Amount: {{ showTotalAmount }}</div>
    </div>

    <nz-table #nzTable
      nzBordered="true" nzSize="small"
      [nzData]="listData"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit" [nzSize]="'default'"
      [nzNoResult]="tplNoData"
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading"
      (nzPageIndexChange)="onDataListPageChanged($event)"
      style="overflow-x: auto;">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No data
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Statement</th>
          <th>Payee</th>
          <th>Denim Carrier</th>
          <th>Quickbooks Vendor</th>
          <th>Payable Amount</th>
          <th>Duration</th>
          <th>Total Routes</th>
          <th *ngIf="shouldShowPodStatus">POD status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
          <tr>
            <td><a [routerLink]="getRouterLinkDetail(item)"><code>{{ showStatementCode(item) }}</code></a></td>
            <!-- payee -->
            <td>
              <a [href]="getLinkCarrierDetail(item)" target="_blank" nz-tooltip nzTooltipTitle="Detail carrier">{{ item.account.name }}</a>
              <i *ngIf="!isHasPaymentInfo(item)" nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipTitle="Payment method is missing" style="color: #faad14; margin-left: 10px;"></i>
            </td>
            <!-- denim carrier -->
            <td>
              <div>
                <span>{{showDenimInfo(item)}}</span>
                <button *ngIf="shouldEditDenim" nz-button nzType="link" (click)="onBtnEditDenim(item)"><i nz-icon nzType="edit"></i></button>
              </div>
            </td>
            <!-- quickbooks vendor -->
            <td>
              <a *ngIf="quickbooksVendorLink(item)" [href]="quickbooksVendorLink(item)" target="_blank" nz-tooltip nzTooltipTitle="Go to quickbooks">
                {{ showQuickbooksVendor(item) }}
              </a>
            </td>
            <td>
              <div>{{ showAmountItem(item) }}</div>
            </td>
            <td>{{ showRouteCompletedWindow(item) }}</td>
            <td>{{ showTotalRoute(item) }}</td>
            <td *ngIf="shouldShowPodStatus">
              <nz-tag class="pod-status" [ngClass]="item.podStatus">{{ showPodStatus(item) }}</nz-tag>
            </td>
            <td>
              <ng-container>
                <div class="group-action">
                  <button *ngIf="shouldCreateBill" class="action-btn" nz-button nzType="link" (click)="onBtnCreateBill(item)">Create bill</button>
                  <button *ngIf="shoudldRemoveStatement" class="action-btn" nz-button nzType="link" nzDanger="true" (click)="onBtnRemoveStatement(item)">Remove</button>
                </div>
              </ng-container>
              <ng-container *ngIf="isTabStatementSubmitted">
                <div>
                  <a *ngIf="quickbooksBillLink(item)" [href]="quickbooksBillLink(item)" target="_blank">View on Quickbooks</a>
                </div>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>