import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";
import { FinStatementTimeWindow } from "@wearewarp/types/data-model/types/TimeWindow";
import { QBStatementWindow } from "../../interface";

@Component({
  selector: '[confirm_create_statement_dialog]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ConfirmCreateQBStatementDialog extends BaseDialog {
  @Input() routeCompletedWindow: FinStatementTimeWindow;
  @Input() windowOption: number;

  public needCustomWindow = false;
  public customWindow: FinStatementTimeWindow = {
    timezone: 'America/New_York',
  }
  public customWindowFrom: Date;
  public customWindowTo: Date;

  ngOnInit() {
    super.ngOnInit();
    this.customWindow = Object.assign({}, this.routeCompletedWindow);
    let timezone = this.customWindow.timezone || 'America/New_York';
    this.customWindowFrom = DateUtil.convertLocalTime2(this.customWindow.from, timezone);
    this.customWindowTo = DateUtil.convertLocalTime2(this.customWindow.to, timezone);
  }

  get windowLabel() {
    switch(this.windowOption) {
      case QBStatementWindow.lastWeek: return 'Last week';
      case QBStatementWindow.last4Weeks: return 'Last 4 weeks';
      case QBStatementWindow.last8Weeks: return 'Last 8 weeks';
      default: return 'N/A';
    }
  }

  showRouteCompletedWindow() {
    const windowInfo: FinStatementTimeWindow = this.routeCompletedWindow;
    const timezone = windowInfo.timezone || 'America/New_York';
    const format = 'DD MMM';
    let timeFrom = DateUtil.displayLocalTime(windowInfo.from, {timezone, format });
    let timeTo = DateUtil.displayLocalTime(windowInfo.to, {timezone, format });
    let dayFrom = DateUtil.displayLocalTime(windowInfo.from, {timezone, format: 'ddd' })
    let dayTo = DateUtil.displayLocalTime(windowInfo.to, {timezone, format: 'ddd' });
    return `${dayFrom}-${dayTo}: ${timeFrom} -> ${timeTo}`;
  }

  onChangeCustomWindow(value, key) {
    let timezone = this.customWindow.timezone;
    switch (key) {
      case 'from': 
        this.customWindow.from = DateUtil.convertLocalTime(this.customWindowFrom, timezone)?.toISOString();
        return;
      case 'to':
        this.customWindow.to = DateUtil.convertLocalTime(this.customWindowTo, timezone)?.toISOString();
        return;
      default: return;
    }
  }
}